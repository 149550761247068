<template>
  <div>
    <!-- Tambah To Soal -->
    <b-modal
      id="tambah-paket-soal"
      size="md"
      centered
      title="Tambah Paket"
      cancel-title="Close"
      ok-title="Simpan"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="submitPaket"
    >
      <b-form>
        <b-form-group label="Kategori Mata Pelajaran" label-for="mapel">
          <v-select
            id="mapel"
            v-model="form.mapel_soal_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="listMapelMapped"
            :reduce="(mapel) => mapel.value"
          />
        </b-form-group>
        <b-form-group label="Tipe Perhitungan" label-for="mapel">
          <v-select
            id="tipePerhitungan"
            v-model="form.tipe_perhitungan"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :options="listTipePerhitungan"
            :reduce="(item) => item.value"
          />
        </b-form-group>

        <b-form-group label="Ambang Batas Atas" label-for="ambangAtas">
          <b-form-input
            placeholder="Masukkan Ambang Atas"
            type="number"
            v-model="form.ambang_batas_atas"
          />
        </b-form-group>

        <b-form-group label="Ambang Batas Bawah" label-for="ambangAtas">
          <b-form-input
            placeholder="Masukkan Ambang Bawah"
            type="number"
            v-model="form.ambang_batas_bawah"
          />
        </b-form-group>
        
        <b-form-group label="Bobot Benar" label-for="bobotBenar">
          <b-form-input
            placeholder="Masukkan Bobot Benar"
            type="number"
            v-model="form.bobot_benar"
          />
        </b-form-group>
        
        <b-form-group label="Bobot Kosong" label-for="bobotKosong">
          <b-form-input
            placeholder="Masukkan Bobot Kosong"
            type="number"
            v-model="form.bobot_kosong"
          />
        </b-form-group>

        <b-form-group label="Bobot Salah" label-for="bobotSalah">
          <b-form-input
            placeholder="Masukkan Bobot Salah"
            type="number"
            v-model="form.bobot_salah"
          />
        </b-form-group>

        <b-form-group label="Nama Paket" label-for="namaPaket">
          <b-form-input
            v-model="form.nama"
            id="namaPaket"
            placeholder="Masukkaan nama paket"
          />
        </b-form-group>

        <b-form-group label="File excel" label-for="fileExcel">
          <b-form-file
            id="fielExcel"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".xls, .xlsx"
            @change="handleFileUpload"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Edit To Soal -->
    <b-modal
      id="edit-paket-soal"
      size="md"
      centered
      title="Edit Paket"
      cancel-title="Close"
      ok-title="Ubah"
      cancel-variant="outline-secondary"
      @ok="editPaket"
      no-close-on-backdrop
    >
      <b-form>
        <b-form-group label="Kategori Mata Pelajaran" label-for="mapel">
          <v-select
            id="mapel"
            v-model="form.mapel_soal_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="listMapelMapped"
            :reduce="(mapel) => mapel.value"
          />
        </b-form-group>
        <b-form-group label="Nama Paket" label-for="namaPaket">
          <b-form-input
            v-model="form.nama"
            id="namaPaket"
            placeholder="Masukkaan nama paket"
          />
        </b-form-group>

        <b-form-group label="Tipe Perhitungan" label-for="mapel">
          <v-select
            id="tipePerhitungan"
            v-model="form.tipe_perhitungan"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :options="listTipePerhitungan"
            :reduce="(item) => item.value"
          />
        </b-form-group>

        <b-form-group label="Ambang Batas Atas" label-for="ambangAtas">
          <b-form-input
            placeholder="Masukkan Ambang Atas"
            type="number"
            v-model="form.ambang_batas_atas"
          />
        </b-form-group>

        <b-form-group label="Bobot Benar" label-for="bobotBenar">
          <b-form-input
            placeholder="Masukkan Bobot Benar"
            type="number"
            v-model="form.bobot_benar"
          />
        </b-form-group>
        
        <b-form-group label="Bobot Kosong" label-for="bobotKosong">
          <b-form-input
            placeholder="Masukkan Bobot Kosong"
            type="number"
            v-model="form.bobot_kosong"
          />
        </b-form-group>

        <b-form-group label="Bobot Salah" label-for="bobotSalah">
          <b-form-input
            placeholder="Masukkan Bobot Salah"
            type="number"
            v-model="form.bobot_salah"
          />
        </b-form-group>

        <b-form-group label="Ambang Batas Bawah" label-for="ambangAtas">
          <b-form-input
            placeholder="Masukkan Ambang Bawah"
            type="number"
            v-model="form.ambang_batas_bawah"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Delete Try Out -->
    <b-modal
      id="hapus-paket-soal"
      cancel-variant="outline-secondary"
      ok-title="Hapus"
      cancel-title="Close"
      centered
      title="Hapus Soal"
      @ok="deletePaket(form.id)"
    >
      <b-form>
        <b-form-group>
          <p>Apakah anda akan menghapus Soal ini?</p>
        </b-form-group>
      </b-form>
    </b-modal>

    <div class="tw-mb-5">
      <!-- Table Top -->
      <div
        class="d-flex tw-mb-[15px] tw-justify-center tw-gap-3 tw-items-center"
      >
        <!-- <b-form-input v-model="searchQuery" class="mr-1 d-inline-block" placeholder="Search..." /> -->
        <search-input
          :onInput="onSearch"
          :placeholder="'Cari berdasarkan ID atau Nama Paket..'"
        />
        <b-button variant="primary" @click="fetchPaket">
          <feather-icon icon="RefreshCcwIcon" class="mr-50" />
          <span class="text-nowrap">Refresh Data</span>
        </b-button>

        <b-button variant="primary" v-b-modal.tambah-paket-soal>
          <feather-icon icon="PlusCircleIcon" class="mr-50" />
          <span class="text-nowrap">Tambah Soal</span>
        </b-button>
      </div>

      <div class="tw-inline-flex">
        <custom-filter
          :data="filterList"
          :filterValue="filterValue"
          :initialValue="initialValue"
          :handleChange="handleChange"
        />
      </div>
    </div>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-table
        :busy="isLoading"
        ref="refSoalListTable"
        class="position-relative"
        :items="listPaket"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        hover
        empty-text="Tidak ada data ditemukan"
      >
        <template #table-busy>
          <div class="my-2 text-center text-danger">
            <b-spinner class="mr-1 align-middle"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>

        <template #cell(pertanyaan)="data">
          <div v-html="data.item.pertanyaan"></div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="getPaket(data.item.id)"
              :to="{
                name: 'manajemen-paket-mapel-detail',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Lihat Soal</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="getPaket(data.item.id)"
              v-b-modal.edit-paket-soal
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="getPaket(data.item.id)"
              v-b-modal.hapus-paket-soal
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <pagination
          :from="from"
          :to="to"
          :pageTotal="pageTotal"
          :total="total"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BFormGroup,
  BCardText,
  BSpinner,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onMounted, computed, watch } from "@vue/composition-api";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CustomFilter from "@/@core/components/base/CustomFilter.vue";
import { useRouter } from "@core/utils/utils";
import debounce from "lodash.debounce";

import repository from "@repofactory";
import SearchInput from "@/@core/components/base/SearchInput.vue";
import Pagination from "@/@core/components/base/Pagination.vue";
const repoPaket = repository.get("paketMapelRepository");
const repoMapel = repository.get("mapelRepository");

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BSpinner,
    BForm,
    BFormGroup,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardActions,
    BFormFile,
    CustomFilter,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    SearchInput,
    Pagination,
  },
  setup() {
    const listPaket = ref([]);
    const listMapel = ref([]);
    const toast = useToast();
    const { route } = useRouter();

    const isLoading = ref(true);
    const isSubmitting = ref(false);
    const isError = ref(false);

    const from = ref(0);
    const to = ref(0);
    const pageTotal = ref(0);
    const total = ref(0);

    const keyword = ref("");

    const form = ref({
      id: null,
      nama: "",
      tipe_perhitungan: null,
      mapel_soal_id: null,
      index: null,
      excel: null,
      ambang_batas_atas: null,
      ambang_batas_bawah: null,
      bobot_benar: null,
      bobot_kosong: null,
      bobot_salah: null,
    });

    const tableColumns = [
      { key: "id", label: "ID", sortable: true },
      {
        key: "nama",
        label: "Nama Paket",
        sortable: true,
        formatter: (value, key, item) => value.toUpperCase(),
      },
      {
        key: "mapel_soal_id",
        label: "Mata Pelajaran",
        sortable: true,
        formatter: (value, key, item) =>
          listMapel.value.find((val) => val.id == value).nama.toUpperCase(),
      },
      {
        key: "tipe_perhitungan",
        label: "Tipe Perhitungan",
        sortable: true,
        formatter: (value, key, item) => value.toUpperCase(),
      },
      {
        key: "soals_count",
        label: "Butir Soal",
        sortable: true,
      },
      {
        key: "ambang_batas_atas",
        label: "Batas Atas",
        sortable: true,
        formatter: (value, key, item) => value,
      },
      {
        key: "ambang_batas_bawah",
        label: "Batas Bawah",
        sortable: true,
        formatter: (value, key, item) => value,
      },
      {
        key: "bobot_benar",
        label: "Bobot Benar",
        sortable: true,
        formatter: (value, key, item) => value,
      },
      {
        key: "bobot_kosong",
        label: "Bobot Kosong",
        sortable: true,
        formatter: (value, key, item) => value,
      },
      {
        key: "bobot_salah",
        label: "Bobot Salah",
        sortable: true,
        formatter: (value, key, item) => value,
      },
      { key: "actions" },
    ];

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const resetModal = () => {
      form.value = {
        id: null,
        nama: "",
        tipe_perhitungan: null,
        mapel_soal_id: null,
        index: null,
        excel: null,
      };
    };

    const fetchPaket = async (page = 1, keyword = "", mata_pelajaran = "") => {
      isLoading.value = true;
      fetchMapel(page, keyword, mata_pelajaran);
      await repoPaket
        .get(page, keyword, mata_pelajaran, 10)
        .then(function (response) {
          isLoading.value = false;
          listPaket.value = response.data.data.data;

          from.value = response.data.data.from;
          to.value = response.data.data.to;
          pageTotal.value = response.data.data.last_page;

          total.value = response.data.data.total;
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isLoading.value = false;
          isError.value = true;
        });
    };

    const listMapelMapped = computed(() => {
      return listMapel.value.map((val) => {
        return {
          title: val.nama.toUpperCase(),
          value: parseInt(val.id),
        };
      });
    });

    const listTipePerhitungan = [
      {
        label: "UTBK",
        value: "utbk",
      },
      {
        label: "BNS",
        value: "bns",
      },
      {
        label: "SKD",
        value: "skd",
      },
      {
        label: "IRT",
        value: "irt",
      },
    ];

    const getPaket = (id) => {
      const index = listPaket.value.findIndex((paket) => paket.id == id);
      const currPaket = listPaket.value[index];
      form.value = {
        id: currPaket.id,
        nama: currPaket.nama,
        mapel_soal_id: currPaket.mapel_soal_id,
        index: index,
        excel: null,
        tipe_perhitungan: currPaket.tipe_perhitungan,
        ambang_batas_atas: currPaket.ambang_batas_atas,
        ambang_batas_bawah: currPaket.ambang_batas_bawah,
        bobot_benar: currPaket.bobot_benar,
        bobot_kosong: currPaket.bobot_kosong,
        bobot_salah: currPaket.bobot_salah,
      };
    };

    const fetchMapel = async () => {
      isLoading.value = true;
      await repoMapel
        .get()
        .then(function (response) {
          const data = response.data.data.map((item) => ({
            label: item.nama,
            value: item.kode,
          }));
          filterList.value[0].list = data;
          listMapel.value = response.data.data;
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        });
    };

    const submitPaket = async () => {
      isSubmitting.value = true;

      let formData = new FormData();
      formData.append("excel", form.value.excel);
      formData.append("tipe_perhitungan", form.value.tipe_perhitungan);
      formData.append("nama", form.value.nama);
      formData.append("mapel_soal_id", form.value.mapel_soal_id);
      formData.append("ambang_batas_atas", form.value.ambang_batas_atas);
      formData.append("ambang_batas_bawah", form.value.ambang_batas_bawah);
      formData.append("bobot_benar", form.value.bobot_benar);
      formData.append("bobot_kosong", form.value.bobot_kosong);
      formData.append("bobot_salah", form.value.bobot_salah);

      await repoPaket
        .create(formData)
        .then((response) => {
          listPaket.value.push(response.data.data);

          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          keyword.value = "";
          resetModal();
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
          resetModal();
        });
    };

    const editPaket = async () => {
      isSubmitting.value = true;

      await repoPaket
        .update(
          {
            mapel_soal_id: form.value.mapel_soal_id,
            nama: form.value.nama,
            ambang_batas_atas: +form.value.ambang_batas_atas,
            ambang_batas_bawah: +form.value.ambang_batas_bawah,
            bobot_benar: +form.value.bobot_benar,
            bobot_kosong: +form.value.bobot_kosong,
            bobot_salah: +form.value.bobot_salah,
            tipe_perhitungan: form.value.tipe_perhitungan,
          },
          form.value.id
        )
        .then((response) => {
          const currPaket = listPaket.value[form.value.index];
          currPaket.nama = response.data.data.nama;
          currPaket.mapel_soal_id = response.data.data.mapel_soal_id;
          currPaket.soals_count = response.data.data.soals_count;
          currPaket.created_at = response.data.data.created_at;
          currPaket.updated_at = response.data.data.updated_at;
          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          keyword.value = "";
          resetModal();

          fetchPaket(
            route.value.query.page,
            keyword.value,
            filterValue.value["Mata Pelajaran"]
          )
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
        });
    };

    const deletePaket = async (id) => {
      isSubmitting.value = true;

      await repoPaket
        .delete(id)
        .then((response) => {
          listPaket.value.splice(form.value.index, 1);

          showToast("Notifikasi", response.data.message, "primary");
          resetModal();

          isSubmitting.value = false;
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
          resetModal();
        });
    };

    const handleFileUpload = (event) => {
      form.value.excel = event.target.files[0];
    };

    const filterList = ref([
      {
        name: "Mata Pelajaran",
        list: [],
      },
    ]);

    const initialValue = ref({
      "Mata Pelajaran": null,
    });

    const filterValue = ref({ ...initialValue });

    onMounted(async () => {
      fetchPaket();
    });

    const onSearch = (e) => {
      keyword.value = e.currentTarget.value;
    };

    const handleChange = (value) => {
      filterValue.value = { ...value };
    };

    watch(
      () => route.value.query.page,
      () =>
        fetchPaket(
          route.value.query.page,
          keyword.value,
          filterValue.value["Mata Pelajaran"]
        )
    );

    watch(
      [keyword, () => filterValue.value],
      debounce(
        () => fetchPaket(1, keyword.value, filterValue.value["Mata Pelajaran"]),
        500
      )
    );

    return {
      filterList,
      filterValue,
      initialValue,
      isError,
      isLoading,
      isSubmitting,
      listPaket,
      tableColumns,
      form,
      listMapelMapped,
      from,
      to,
      pageTotal,
      total,
      keyword,
      listTipePerhitungan,

      // method
      fetchPaket,
      resetModal,
      submitPaket,
      editPaket,
      deletePaket,
      handleFileUpload,
      getPaket,
      onSearch,
      handleChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
